import ApiService from "../../services/api/api.service";

import { Options } from "../embed.code/models";
import { UserModel } from "../users";

import { CreateAccountDto, UpdateAccountDto } from "./dto";
import AccountModel from "./models/account.model";
import EmbedCodeConfigModel from "./models/embed.code.config.model";

export default class AccountService {
  private static api = new ApiService();

  static createAccount(dto: CreateAccountDto): Promise<AccountModel> {
    return AccountService.api
      .authenticated()
      .post(`/accounts`, dto)
      .then((response) => AccountModel.fromRequest(response.data));
  }

  static updateAccount(accountId: string, dto: UpdateAccountDto): Promise<AccountModel> {
    return AccountService.api
      .authenticated()
      .patch(`/accounts/${accountId}`, dto)
      .then((response) => AccountModel.fromRequest(response.data));
  }

  static fetchAllAccounts(): Promise<AccountModel[]> {
    return AccountService.api
      .authenticated()
      .get(`/accounts`)
      .then((response) => response.data.map(AccountModel.fromRequest));
  }

  static enableAccount(accountId: string): Promise<AccountModel> {
    return AccountService.api
      .authenticated()
      .post(`/accounts/${accountId}/enable`)
      .then((response) => AccountModel.fromRequest(response.data));
  }

  static disableAccount(accountId: string): Promise<AccountModel> {
    return AccountService.api
      .authenticated()
      .post(`/accounts/${accountId}/disable`)
      .then((response) => AccountModel.fromRequest(response.data));
  }

  static fetchAccount(accountId: string): Promise<AccountModel> {
    return AccountService.api
      .authenticated()
      .get(`/accounts/${accountId}`)
      .then((response) => AccountModel.fromRequest(response.data));
  }

  static fetchAccountOwner(accountId: string): Promise<UserModel | null> {
    return AccountService.api
      .authenticated()
      .get(`/accounts/${accountId}/owner`)
      .then((response) => UserModel.fromRequest(response.data))
      .catch((error) => {
        if (error.response?.status === 404) {
          return null;
        } else {
          throw error;
        }
      });
  }

  static fetchEmbedCodeConfig(accountId: string): Promise<EmbedCodeConfigModel | null> {
    return AccountService.api
      .authenticated()
      .get(`/accounts/${accountId}/embed-code`)
      .then((response) => EmbedCodeConfigModel.fromRequest(response.data))
      .catch((error) => {
        if (error.response?.status === 404) {
          return null;
        } else {
          throw error;
        }
      });
  }

  static updateEmbedCodeConfig(accountId: string, data: Options): Promise<EmbedCodeConfigModel> {
    return AccountService.api
      .authenticated()
      .put(`/accounts/${accountId}/embed-code`, data)
      .then((response) => EmbedCodeConfigModel.fromRequest(response.data));
  }

  static createApiKey(accountId: string): Promise<AccountModel> {
    return AccountService.api
      .authenticated()
      .put(`/accounts/${accountId}/api-key`)
      .then((response) => AccountModel.fromRequest(response.data));
  }
}
