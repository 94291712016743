import { useMutation, useQueryClient } from "@tanstack/react-query";

import { qkForAccount } from "../../account";
import { EUserRoleType, useCurrentUserRole } from "../../users";

import { CreatePartnerDto } from "../dto";
import PartnersService from "../partners.service";
import { qkForAccountPartners, qkForSubPartners } from "../query-keys";

export function useCreatePartnerMutation() {
  const queryClient = useQueryClient();
  const role = useCurrentUserRole();

  return useMutation({
    async mutationFn(dto: CreatePartnerDto) {
      if (role?.type === EUserRoleType.AccountOwner) {
        return await PartnersService.createAccountPartner(role.accountId, dto);
      } else if (role?.type === EUserRoleType.Partner) {
        return await PartnersService.createSubPartner(role.partnerId, dto);
      } else {
        throw new Error("Unexpected current role for creating partners");
      }
    },
    onSuccess() {
      if (role?.type === EUserRoleType.AccountOwner) {
        queryClient.invalidateQueries({ queryKey: qkForAccount(role.accountId) });
        queryClient.invalidateQueries({ queryKey: qkForAccountPartners(role.accountId) });
      } else if (role?.type === EUserRoleType.Partner) {
        queryClient.invalidateQueries({ queryKey: qkForAccount(role.accountId) });
        queryClient.invalidateQueries({ queryKey: qkForSubPartners(role.partnerId) });
      }
    },
  });
}

export default useCreatePartnerMutation;
