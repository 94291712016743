import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { EUserRoleType } from "../modules/users/enums";

/** Layouts **/
import EmptyLayout from "../components/layout/empty";
import AppLayout from "../components/layout/app";
import AuthLayout from "../components/layout/auth";
import LandingLayout from "../components/layout/landing";

/** Gates **/
import { AuthSessionGate } from "../components/modules/auth";
import { UserRoleGate, UserRoleProvider } from "../components/modules/users";
import { DisabledAccountGate } from "../components/modules/account";

/** 404 Page Not Found **/
const NotFoundScreen = React.lazy(() => import("../screens/common/not-found"));

/** Auth Screens **/
const LoginScreen = React.lazy(() => import("../screens/auth/login"));
const ResetPasswordScreen = React.lazy(() => import("../screens/auth/reset-password"));
const HomeScreen = React.lazy(() => import("../screens/app/home"));
const OauthFlowScreen = React.lazy(() => import("../screens/app/oauth.flow"));
const UserSetupScreen = React.lazy(() => import("../screens/app/users/user-setup"));

/** App Screens **/
const DashboardScreen = React.lazy(() => import("../screens/app/dashboard"));

const UserProfileScreen = React.lazy(() => import("../screens/app/users/user-profile"));

const EmbedCodeScreen = React.lazy(() => import("../screens/app/integrations/embed.code"));
const PaymentProvidersScreen = React.lazy(() => import("../screens/app/integrations/payment.providers"));
const PushDataScreen = React.lazy(() => import("../screens/app/integrations/push.data"));

const CreateOutgoingHookScreen = React.lazy(() => import("../screens/app/outgoing-hooks/create-outgoing-hook"));
const OutgoingHookDetailsScreen = React.lazy(() => import("../screens/app/outgoing-hooks/outgoing-hook-details"));
const ListOutgoingHooksScreen = React.lazy(() => import("../screens/app/outgoing-hooks/list-outgoing-hooks"));
const UpdateOutgoingHookScreen = React.lazy(() => import("../screens/app/outgoing-hooks/update-outgoing-hook"));

const CreateIncomingHookScreen = React.lazy(() => import("../screens/app/incoming-hooks/create-incoming-hook"));
const IncomingHookDetailsScreen = React.lazy(() => import("../screens/app/incoming-hooks/incoming-hook-details"));
const IncomingHooksOverviewScreen = React.lazy(() => import("../screens/app/incoming-hooks/incoming-hooks-overview"));
const UpdateIncomingHookScreen = React.lazy(() => import("../screens/app/incoming-hooks/update-incoming-hook"));

const CreateLeadScreen = React.lazy(() => import("../screens/app/leads/create-lead"));
const LeadsListScreen = React.lazy(() => import("../screens/app/leads/leads-list"));
const LeadDetailsScreen = React.lazy(() => import("../screens/app/leads/lead-details"));
const UpdateLeadScreen = React.lazy(() => import("../screens/app/leads/update-lead"));

const CreateSaleScreen = React.lazy(() => import("../screens/app/sales/create-sale"));

const CreateLinkBlueprintScreen = React.lazy(() => import("../screens/app/links/create-link-blueprint"));
const LinksOverviewScreen = React.lazy(() => import("../screens/app/links/links-overview"));
const LinkBlueprintDetailsScreen = React.lazy(() => import("../screens/app/links/link-blueprint-details"));
const UpdateLinkBlueprintScreen = React.lazy(() => import("../screens/app/links/update-link-blueprint"));

const PartnersListScreen = React.lazy(() => import("../screens/app/partners/partners-list"));
const CreatePartnerScreen = React.lazy(() => import("../screens/app/partners/create-partner"));
const UpdatePartnerScreen = React.lazy(() => import("../screens/app/partners/update-partner"));
const PartnerDetailsScreen = React.lazy(() => import("../screens/app/partners/partner-details"));

const PayoutsOverviewScreen = React.lazy(() => import("../screens/app/rewards/payouts-overview"));
const PayoutDetailsScreen = React.lazy(() => import("../screens/app/rewards/payout-details"));

const AccountSettingsScreen = React.lazy(() => import("../screens/app/accounts/account-settings"));

/** Admin Screens **/
const AccountsListScreen = React.lazy(() => import("../screens/admin/accounts/accounts.list"));
const CreateAccountScreen = React.lazy(() => import("../screens/admin/accounts/create.account"));
const AccountsDetailsScreen = React.lazy(() => import("../screens/admin/accounts/account.details"));

// TODO: come up with a better way to do authenticated routes. The method of wrapping protected routes in a "gate"
// component worked well for coarse-grained protections, such as authenticated vs non-authenticated, but becomes messy
// when you have to start splitting routes between the "account owner" section and the "account owner OR partner"
// section.
//
// Tbh, refactoring the entire router probably wouldn't be a bad idea. Whether we should stick with React Router, or
// switch to something like TanStack Router or "wouter", is definitely worth considering.

export function AppRouter() {
  return (
    <Routes>
      <Route element={<LandingLayout />}>
        <Route path="*" element={<NotFoundScreen />} />
      </Route>

      <Route path="user-setup" element={<UserSetupScreen />} />

      <Route path="auth" element={<AuthLayout />}>
        <Route path="sign-in" element={<LoginScreen />} />
        <Route path="reset-password" element={<ResetPasswordScreen />} />
      </Route>

      <Route element={<AuthSessionGate />}>
        {/* TODO: This feels a bit messy since we had to move oauth stuff back inside the auth-session/user-role gates
         * (otherwise, we didn't have the current account ID to finalize the oauth flow). Is there a way we can clean
         * this up again? */}
        <Route
          path="oauth"
          element={
            <UserRoleProvider>
              <EmptyLayout />
            </UserRoleProvider>
          }
        >
          <Route path=":provider" element={<OauthFlowScreen />} />
        </Route>

        <Route
          element={
            <UserRoleProvider>
              <AppLayout />
            </UserRoleProvider>
          }
        >
          <Route index element={<HomeScreen />} />

          <Route path="user">
            <Route index element={<Navigate to="profile" replace />} />
            <Route path="profile" element={<UserProfileScreen />} />
          </Route>

          <Route element={<UserRoleGate roleTypes={[EUserRoleType.AccountOwner, EUserRoleType.Partner]} />}>
            <Route element={<DisabledAccountGate />}>
              <Route path="dashboard" element={<DashboardScreen />} />

              <Route path="partners">
                <Route index element={<PartnersListScreen />} />
                <Route path="new" element={<CreatePartnerScreen />} />
                <Route path=":partnerId" element={<PartnerDetailsScreen />} />
                <Route path=":partnerId/update" element={<UpdatePartnerScreen />} />
              </Route>

              <Route path="leads">
                <Route index element={<LeadsListScreen />} />
                <Route path=":leadId" element={<LeadDetailsScreen />} />
              </Route>
            </Route>
          </Route>

          <Route element={<UserRoleGate roleTypes={[EUserRoleType.AccountOwner]} />}>
            <Route element={<DisabledAccountGate />}>
              <Route path="leads">
                <Route path="new" element={<CreateLeadScreen />} />
                <Route path=":leadId/update" element={<UpdateLeadScreen />} />
                <Route path=":leadId/new-sale" element={<CreateSaleScreen />} />
              </Route>

              <Route path="links">
                <Route index element={<LinksOverviewScreen />} />
                <Route path="new" element={<CreateLinkBlueprintScreen />} />
                <Route path=":blueprintId" element={<LinkBlueprintDetailsScreen />} />
                <Route path=":blueprintId/update" element={<UpdateLinkBlueprintScreen />} />
              </Route>

              <Route path="payouts">
                <Route index element={<PayoutsOverviewScreen />} />
                <Route path=":payoutId" element={<PayoutDetailsScreen />} />
              </Route>

              <Route path="account">
                <Route index element={<Navigate to="settings" replace />} />
                <Route path="settings" element={<AccountSettingsScreen />} />
              </Route>

              <Route path="integrations">
                <Route path="push-data" element={<PushDataScreen />} />
                <Route path="embed-code" element={<EmbedCodeScreen />} />
                <Route path="payment-providers" element={<PaymentProvidersScreen />} />

                <Route path="outgoing-webhooks" element={<ListOutgoingHooksScreen />} />
                <Route path="outgoing-webhooks/new" element={<CreateOutgoingHookScreen />} />
                <Route path="outgoing-webhooks/:hookId" element={<OutgoingHookDetailsScreen />} />
                <Route path="outgoing-webhooks/:hookId/update" element={<UpdateOutgoingHookScreen />} />

                <Route path="incoming-webhooks" element={<IncomingHooksOverviewScreen />} />
                <Route path="incoming-webhooks/new" element={<CreateIncomingHookScreen />} />
                <Route path="incoming-webhooks/:hookId" element={<IncomingHookDetailsScreen />} />
                <Route path="incoming-webhooks/:hookId/update" element={<UpdateIncomingHookScreen />} />
              </Route>
            </Route>
          </Route>

          <Route path="admin" element={<UserRoleGate roleType={EUserRoleType.Admin} />}>
            <Route index element={<Navigate to="accounts" replace />} />
            <Route path="accounts" element={<AccountsListScreen />} />
            <Route path="accounts/new" element={<CreateAccountScreen />} />
            <Route path="accounts/:id" element={<AccountsDetailsScreen />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRouter;
