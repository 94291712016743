import React from "react";

import { EUserRoleType } from "../../../../modules/users/enums";
import { useCurrentUserRole } from "../../../../modules/users/hooks";

import {
  AccountSettingsLink,
  AdminLink,
  DashboardLink,
  EmbedCodeLink,
  IncomingWebhooksLink,
  LeadsLink,
  LinksLink,
  OutgoingWebhooksLink,
  PartnersLink,
  PaymentProvidersLink,
  PayoutsLink,
  PushDataLink,
} from "./sidebar-links";
import { SidebarSection } from "./sidebar-section";

import styles from "./styles.module.scss";

export function SidebarNav() {
  const role = useCurrentUserRole();
  const roleType = role?.type;

  return (
    <div className={styles.container}>
      {roleType === EUserRoleType.Admin ? (
        <SidebarSection>
          <AdminLink />
        </SidebarSection>
      ) : roleType === EUserRoleType.AccountOwner ? (
        <>
          <SidebarSection>
            <DashboardLink />
            <PartnersLink />
            <LeadsLink />
            <LinksLink />
            <PayoutsLink />
            <AccountSettingsLink />
          </SidebarSection>

          <SidebarSection label="Integrations">
            <PaymentProvidersLink />
            <IncomingWebhooksLink />
            <OutgoingWebhooksLink />
            <PushDataLink />
            <EmbedCodeLink />
          </SidebarSection>
        </>
      ) : (
        roleType === EUserRoleType.Partner && (
          <SidebarSection>
            <DashboardLink />
            <PartnersLink />
            <LeadsLink />
          </SidebarSection>
        )
      )}
    </div>
  );
}

export default SidebarNav;
