export const qkForAllPartnerData = () => ["partners"];

export const qkForAccountPartners = (accountId: string) => ["partners", "for-account", accountId];
export const qkForSubPartners = (partnerId: string) => ["partners", "for-partner", partnerId];

export const qkForPartner = (partnerId: string) => ["partners", partnerId];
export const qkForPartnerHistoricalStats = (partnerId: string, period: string) => [
  "partners",
  partnerId,
  "historical-stats",
  period,
];
export const qkForPartnerInvitationStatus = (partnerId: string) => ["partners", partnerId, "invitation-status"];
