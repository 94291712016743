import ApiService from "../../services/api/api.service";

import { CreatePartnerDto, UpdatePartnerDto } from "./dto";
import { PartnerInvitationState, PartnerModel } from "./models";

export class PartnersService {
  private static api = new ApiService();

  static createAccountPartner(accountId: string, dto: CreatePartnerDto): Promise<PartnerModel> {
    return this.api
      .authenticated()
      .post(`/accounts/${accountId}/partners`, dto)
      .then((response) => PartnerModel.fromRequest(response.data));
  }

  static createSubPartner(partnerId: string, dto: CreatePartnerDto): Promise<PartnerModel> {
    return this.api
      .authenticated()
      .post(`/partners/${partnerId}/partners`, dto)
      .then((response) => PartnerModel.fromRequest(response.data));
  }

  static updatePartner(partnerId: string, dto: UpdatePartnerDto): Promise<PartnerModel> {
    return this.api
      .authenticated()
      .put(`/partners/${partnerId}`, dto)
      .then((response) => PartnerModel.fromRequest(response.data));
  }

  static fetchAccountPartners(accountId: string): Promise<PartnerModel[]> {
    return this.api
      .authenticated()
      .get(`/accounts/${accountId}/partners`)
      .then((response) => response.data.map(PartnerModel.fromRequest));
  }

  static fetchSubPartners(partnerId: string): Promise<PartnerModel[]> {
    return this.api
      .authenticated()
      .get(`/partners/${partnerId}/partners`)
      .then((response) => response.data.map(PartnerModel.fromRequest));
  }

  static fetchPartner(partnerId: string): Promise<PartnerModel> {
    return this.api
      .authenticated()
      .get(`/partners/${partnerId}`)
      .then((response) => PartnerModel.fromRequest(response.data));
  }

  static async downloadAccountPartnersCsv(accountId: string) {
    await this.api
      .authenticated()
      .download(`/accounts/${accountId}/partners/csv`, { fallbackFileName: "partners.csv" });
  }

  static async downloadSubPartnersCsv(partnerId: string) {
    await this.api
      .authenticated()
      .download(`/partners/${partnerId}/partners/csv`, { fallbackFileName: "partners.csv" });
  }

  static deletePartner(partnerId: string) {
    return this.api.authenticated().delete(`/partners/${partnerId}`);
  }

  static fetchPartnerInvitationState(partnerId: string): Promise<PartnerInvitationState> {
    return this.api
      .authenticated()
      .get(`/partners/${partnerId}/invitation`)
      .then((response) => PartnerInvitationState.fromRequest(response.data));
  }

  static resendPartnerInvitation(partnerId: string): Promise<void> {
    return this.api.authenticated().post(`/partners/${partnerId}/invitation/retries`).then();
  }
}

export default PartnersService;
