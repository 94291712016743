import { EUserRoleType } from "../enums";

export type UserRole = AdminRole | AccountOwnerRole | PartnerRole;

export type AdminRole = {
  type: EUserRoleType.Admin;
};

export type AccountOwnerRole = {
  type: EUserRoleType.AccountOwner;
  accountId: string;
};

export type PartnerRole = {
  type: EUserRoleType.Partner;
  accountId: string;
  partnerId: string;
};

export function areUserRolesEqual(r1: UserRole, r2: UserRole): boolean {
  return JSON.stringify(r1) === JSON.stringify(r2);
}

export function getUserRoleAccountId(role: UserRole): string | null {
  return "accountId" in role ? role.accountId : null;
}
