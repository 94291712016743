export const qkForAllAccountData = () => ["accounts"];

export const qkForAllAccounts = () => ["accounts", "list"];
export const qkForSomeAccounts = (accountIds: string[]) => ["accounts", "list", ...accountIds];

export const qkForSomeAccountOwners = (accountIds: string[]) => ["accounts", "owners", ...accountIds];

export const qkForAccount = (accountId: string) => ["accounts", accountId];
export const qkForAccountOwner = (accountId: string) => ["accounts", accountId, "owner"];
export const qkForAccountEmbedCodeConfig = (accountId: string) => ["accounts", accountId, "embed-code-config"];
export const qkForAccountStats = (accountId: string) => ["accounts", accountId, "stats"];
export const qkForAccountHistoricalStats = (accountId: string, period: string) => [
  "accounts",
  accountId,
  "historical-stats",
  period,
];
