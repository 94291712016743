import { useMutation, useQueryClient } from "@tanstack/react-query";

import { qkForAllRewardData } from "../../rewards/query-keys";

import AccountService from "../account.service";
import { UpdateAccountDto } from "../dto";
import { qkForAccount, qkForAllAccounts } from "../query-keys";
import { useCurrentAccountId } from "./use-current-account-id";

export function useUpdateCurrentAccountMutation() {
  const queryClient = useQueryClient();
  const accountId = useCurrentAccountId() ?? "";

  return useMutation({
    mutationFn: (dto: UpdateAccountDto) => AccountService.updateAccount(accountId, dto),
    onSuccess(account) {
      queryClient.invalidateQueries({ queryKey: qkForAllAccounts() });
      queryClient.setQueryData(qkForAccount(account.id), account);

      // If the payout delay was changed, then the status of rewards may have changed.
      queryClient.invalidateQueries({ queryKey: qkForAllRewardData() });
    },
  });
}

export default useUpdateCurrentAccountMutation;
