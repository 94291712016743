import React from "react";

import useDomId from "../../../../modules/common/hooks/use-dom-id";

import styles from "./styles.module.scss";

type Props<T> = {
  options: T[];
  value: T;
  onChange?: (option: T) => void;
  getOptionKey?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => string;
  disabled?: boolean;
  className?: string;
  itemClassName?: string;
};

export function InlineRadioInput<T>(props: Props<T>) {
  const { options, value, onChange, disabled, className, itemClassName } = props;

  const getOptionKey = props.getOptionKey;
  const getOptionLabel = props.getOptionLabel ?? String;
  const getOptionValue = props.getOptionValue ?? String;

  const getOptionByValue = (value: string): T => {
    const option = options.find((o) => getOptionValue(o) === value);
    if (option) {
      return option;
    } else {
      throw new Error("unknown option value");
    }
  };

  const name = useDomId();

  return (
    <div className={styles.container + " " + className ?? ""}>
      {options.map((option, index) => {
        const active = option === value;

        return (
          <label
            key={getOptionKey?.(option) ?? index}
            className={styles.label + " " + itemClassName ?? ""}
            data-active={active}
            data-disabled={disabled}
          >
            <input
              type="radio"
              name={name}
              value={getOptionValue(option)}
              checked={active}
              disabled={disabled}
              onChange={(event) => onChange?.(getOptionByValue(event.target.value))}
              className={styles.input}
            />
            {getOptionLabel(option)}
          </label>
        );
      })}
    </div>
  );
}

export default InlineRadioInput;
